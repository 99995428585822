.RootJamCard{
  z-index: 9999;
  position: fixed;
  top: 50px;
  left: 1rem;
  width: 30vw;
}
.JamHeader{
  padding: 10px !important;
  border-bottom: 1px solid #c6c6c6;
}
.JamHeader span{
  font-size: 1rem;
}
.jamContainer{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.listText{
  font-size: 0.7rem;
  margin: 0 !important;
}
.listText span {
  font-size: 0.7rem;
}
.listText p {
  font-size: 0.7rem;
}