.Message {
    margin-top: 4px;
    margin-right: 2px;
    float: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 2vh;
    display: flex;
    align-items: center;
}

.Light {
    color: #415575;
}

.Dark {
    color: white;
}