.ImageMessage {
    width: 100%;
    height: 100%;
}

.MessageText {
    margin-top: 5px;
    margin-left: 10px;
    font-weight: 500;
    /* with description */
    font-family: Roboto;
    font-size: 2vh;
    font-weight: bold;
    float: left;
    margin-right: 5px;
}

.Icon {
    height: 60%;
}

.LightIcon {
    color: #415575;
}

.DarkIcon {
    color: #F6B73C;
}

.LightMessage {
    color: #000000;
}

.LightDescription {
    color: #526788;
}

.DarkMessage {
    color: white;
}

.DarkDescription {
    color: #cacaca;
    /* background-color: red; */
}