.TopPanel {
    width: 100%;
    height: 100%;
}

.Row1 {
    height: 100%;
    padding: 2px;
    width: 55%;
    float: left;
    /* background-color: khaki; */
}

.Row2 {
    height: 100%;
    width: 7%;
    padding-top: 10px;
    float: left;
    /* background-color: indigo; */
}

.Row3 {
    height: 100%;
    padding-top: 5px;
    float: left;
    width: 8%;
    /* background-color: hotpink; */
}

.Row4 {
    float: left;
    /* background-color: khaki; */
    width: 15%;
    height: 100%;
}

.Row5 {
    height: 60%;
    width: 10%;
    margin-top: 10px;
    float: left;
    border-left: solid rgb(97, 97, 97) 1px;
    /* background-color: greenyellow; */
}

.Row6 {
    height: 100%;
    width: 3%;
    float: left;
    /* background-color: rgb(47, 189, 255); */
}

.Email {
    font-family: Roboto;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0em;
    text-align: right;
}

.User {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: right;
}

.UserIcon {
    margin-top: 25%;
    margin-left: 25%;
    color: #233045;
    background-color: #EEEEEE;
    border-radius: 100%;
    padding: 1px;
}

.UserIcon:hover {
    cursor: pointer;
}

.Light {
    color: rgb(0, 0, 0);
}

.Dark {
    color: white;
}

.DarkMode,
.LightMode {
    width: 12%;
    margin: 8px;
    height: 50%;
    background: white;
    border-radius: 8px;
    float: left;
}

.DarkMode {
    margin-left: 20px;
}

.DarkMode {
    border: solid gray 2px;
    background-color: #2A3A52;
}

.LightMode {
    border: solid gray 2px;
    background-color: #eeeeee;
}

.DarkMode:hover,
.LightMode:hover {
    cursor: pointer;
}

.DarkModeSelected {
    border: solid yellow 2px;
}

.LightModeSelected {
    border: solid rgb(26, 26, 25) 2px;
}

.DarkExpand {
    color: gray;
    padding: 9px;
}

.LightExpand {
    color: white;
    padding: 9px;
}

.LightExpand:hover,
.DarkExpand:hover {
    cursor: pointer;
}

.Logo1 {
    height: 80%;
    padding: 5px;
    border-radius: 3px;
    margin-left: 2%;
}

.Logo2 {
    height: 95%;
    margin: .5px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    margin-left: 5%;
}