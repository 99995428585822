@font-face {
    font-family: "Roboto";
    src: local("Roboto"), url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: bold;
}

.Container {
    position: absolute;
    height: 100%;
    width: 100%;
}

.DarkBG {
    background-color: #142135;
}

.DarkGradient {
    background-color: #0C131E;
}

.TopPanelRow {
    /* background-color: khaki; */
    height: 7.1%;
    width: 100%;
    border-bottom: solid gray 1px;
    border-top: solid gray 1px;
}

.ContentPanelRow {
    z-index: 2;
    height: 89%;
    width: 100%;
}

.Copyrights {
    height: 2.5%;
    padding-top: 0.5%;
    padding-left: 1%;
    font-family: Roboto;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #949494;
}

.ContentCol1 {
    position: absolute;
    float: left;
    width: 75%;
    height: 92.6%;
    margin-left: 1%;
}

.ContentCol2 {
    position: absolute;
    left: 78%;
    width: 21%;
    height: 92.6%;
}

.SearchPanel {
    /* background-color: khaki; */
    width: 100%;
    z-index: 1;
    height: 8.5%;
}

.MapPanel {
    /* background-color: khaki; */
    width: 100%;
    z-index: 0;
    height: 55%;
}

.BottomPanel {
    /* background-color: teal; */
    width: 100%;
    height: 27%;
    margin-top: 2.5%;
}

.Line {
    position: absolute;
    z-index: 5;
    left: 77%;
    height: 86%;
    margin-top: 1%;
}

.ModalPanel {
    position: absolute;
}

.LightBG {
    background-color: #EEEEEE;
}

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: gray
}

::-webkit-scrollbar-thumb:window-inactive {
    background: gray
}