.Camera {
    width: 100%;
    height: 100%;
    position: relative;
}

.Main {
    position: absolute;
    overflow-y: auto;
    width: 68%;
    height: 88%;
    margin: 2%;
    padding: 1%;
    filter: drop-shadow(-6px 4px 7px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    background: linear-gradient(180deg, #000000 0%, #142135 100%);
}

.SidePanel {
    position: absolute;
    width: 27%;
    height: 96%;
    margin: 1%;
    margin-left: 71%;
    filter: drop-shadow(-6px 4px 7px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
    background-color: #212832;
}

.Buttons {
    margin-left: 5%;
    display: table-footer-group;
}

.Button {
    float: left;
    padding: 5px;
    margin-right: 10px;
    float: left;
    background: #233045;
    border: 2px solid rgba(236, 224, 205, 0.56);
    color: #EEEEEE;
    font-family: Roboto;
    font-size: 1.5vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 10px;
}

.DateFilter {
    color: white;
    font-family: Roboto;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5%;
}

.Button:hover {
    cursor: pointer;
}

.Clear {
    padding-right: 20px;
}

.ClearIcon {
    padding: 2px;
    background-color: gray;
    margin-left: 70%;
    border-radius: 50%;
    display: inline-block;
}

.Clear:hover {
    cursor: pointer;
}

.Row1 {
    margin: 5%;
}

.Hearder {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Content {
    margin-top: 5%;
    width: 100%;
    height: 80%;
    display: flex;
    /* flex-direction: column; */
}

.Toggles {
    width: 100%;
    height: 7%;
    /* border-bottom: solid gray 1px; */
}

.Toggle {
    width: 30%;
    padding: 5px;
    margin-right: 10px;
    float: left;
    background: #233045;
    font-family: Roboto;
    font-size: 1.5vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 10px;
}

.UnselectedToggle {
    border: 2px solid rgba(236, 224, 205, 0.56);
    color: #EEEEEE;
}

.SelectedToggle {
    border: 2px solid #F6B73C;
    color: #F6B73C;
}

.Toggle:hover {
    cursor: pointer
}

.VideoOptions {
    width: 100%;
    height: 100%;
}

.DateInput {
    margin-inline: 5%;
    width: 90%;
    border: solid gray 1px;
    padding: 2px;
    border-radius: 3px;
    color: white;
    background: #233045;
    outline: none;
}

.HourInput {
    margin-inline: 5%;
    width: 90%;
    border: solid gray 1px;
    padding: 2px;
    border-radius: 3px;
    color: white;
    background: #233045;
    outline: none;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.Label {
    font-family: Roboto;
    font-size: 12px;
    color: white;
    margin-left: 5%;
    margin-top: 5%;
}

.countHeader {
    color: #fff;
    border-top: 1px solid;
    padding: 10px 0px 2px;
    font-size: 12px;
    margin-top: 10px !important;
}

.count {
    color: wheat;
}