.ContactUsLight {
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid #526788;
    box-sizing: border-box;
    border-radius: 28px;
    width: 95px;
    height: 32px;
}

.ContactUsDark {
    background: #142135;
    border: solid white 1px;
    box-sizing: border-box;
    border-radius: 28px;
    width: 95px;
    height: 32px;
}

.Message {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    margin: 6px;
    margin-top: 9px;
    display: flex;
    align-items: center;
    float: left;
}

.Icon {
    margin-top: 2.5px;
}

.Light {
    color: #415575;
}

.Dark {
    color: white;
}