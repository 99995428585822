.Graph {
    width: 100%;
    height: 100%;
}

.Loader {
    margin-left: 40%;
    margin-top: 20%;
    width: 50%;
    height: 50%;
    z-index: 5;
    position: absolute;
}

.Graph1 {
    padding: 5px;
    margin: 10px;
}

.Title {
    font-family: Roboto;
    font-size: 14px;
    color: white;
}