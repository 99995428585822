.Loading {
    height: 100%;
    width: 100%;
}

.Image {
    height: 100%;
    width: 100%;
    position: absolute;
}

.Loader {
    margin-left: 47%;
    margin-top: 20%;
    width: 50%;
    height: 50%;
    z-index: 5;
    position: absolute;
}